<app-header></app-header>
<section class="add-model pb-5">
    <form [formGroup]="addModal">
        <div class="container">
            <button class="backbtn" [routerLink]="['/studio-models']"><img
                    src="assets/images/studiomodule/backbtn.svg"></button>

            <div class="row">
                <div class="col-md-6">

                    <div
                        class="media-wrap img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                        <!-- <img class="img-fluid" [src]="licence==''?'../assets/images/uploadicn.svg':licence"> -->
                        <img *ngIf="licenceData" class="img-fluid" [src]="licenceData">

                        <img *ngIf="!licenceData" class="img-fluid" src="assets/images/studiomodule/imgicn.svg">
                        <!-- <img class="img-fluid" src="assets/images/uploadicn.svg"> -->
                        <p *ngIf="!licenceData" class="fs18 text-center mb-0 mt-3 pt-1">Upload Picture</p>
                        <input type="file" class="upload_input" (change)="onFileChange($event)" autofocus="false"
                            formControlName="profileImage">
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="form-sec">
                        <div class="mb-4 pb-1">
                            <input type="text" formControlName="firstname"  (keydown)="checkEvent($event)"
                                class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Name">
                        </div>
                        
                        <div class="mb-4 pb-1">
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                formControlName="username" placeholder="Measurements" (keydown)="checkEvent($event)">
                        </div>
                        <div class="mb-4 pb-1">
                            <select class="form-select fs18 cmn-input input_60 ff_Roboto"
                                [ngClass]="{'placeholder-color': gendervalue === 'Gender'}"
                                (ngModelChange)="genderClass($event)" [(ngModel)]="gendervalue"
                                formControlName="gendervalue">
                                <option disabled selected hidden value="Gender">Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div class="mb-4 pb-1 position-relative">
                            <mat-chip-list #chipList aria-label="Fruit selection">
                                <input class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Tags..."
                                    [matChipInputFor]="chipList" formControlName="tags"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                                <button class="add_btn position-absolute" click="add($event)">Add</button>
                            </mat-chip-list>

                            <mat-chip class="mt-2 me-2 color_red" *ngFor="let model of modelData" [selectable]="selectable"
                                [removable]="removable" (removed)="remove(model)">
                                {{model.name}}
                                <mat-icon class="" matChipRemove *ngIf="removable">

                                    <span class=" tag_icon">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.33301 0.333984C9.18952 0.333984 10.97 1.07148 12.2828 2.38424C13.5955 3.69699 14.333 5.47747 14.333 7.33398C14.333 9.1905 13.5955 10.971 12.2828 12.2837C10.97 13.5965 9.18952 14.334 7.33301 14.334C5.47649 14.334 3.69601 13.5965 2.38326 12.2837C1.07051 10.971 0.333008 9.1905 0.333008 7.33398C0.333008 5.47747 1.07051 3.69699 2.38326 2.38424C3.69601 1.07148 5.47649 0.333984 7.33301 0.333984ZM7.33301 6.48498L5.70701 4.85898C5.59442 4.7464 5.44173 4.68315 5.28251 4.68315C5.12329 4.68315 4.97059 4.7464 4.85801 4.85898C4.74542 4.97157 4.68217 5.12427 4.68217 5.28348C4.68217 5.4427 4.74542 5.5954 4.85801 5.70798L6.48401 7.33398L4.85801 8.95998C4.80226 9.01573 4.75804 9.08191 4.72787 9.15475C4.6977 9.22758 4.68217 9.30565 4.68217 9.38448C4.68217 9.46332 4.6977 9.54139 4.72787 9.61422C4.75804 9.68706 4.80226 9.75324 4.85801 9.80898C4.91375 9.86473 4.97993 9.90895 5.05277 9.93912C5.12561 9.96929 5.20367 9.98482 5.28251 9.98482C5.36134 9.98482 5.43941 9.96929 5.51225 9.93912C5.58508 9.90895 5.65126 9.86473 5.70701 9.80898L7.33301 8.18298L8.95901 9.80898C9.01475 9.86473 9.08093 9.90895 9.15377 9.93912C9.22661 9.96929 9.30467 9.98482 9.38351 9.98482C9.46235 9.98482 9.54041 9.96929 9.61325 9.93912C9.68608 9.90895 9.75226 9.86473 9.80801 9.80898C9.86375 9.75324 9.90797 9.68706 9.93814 9.61422C9.96831 9.54139 9.98384 9.46332 9.98384 9.38448C9.98384 9.30565 9.96831 9.22758 9.93814 9.15475C9.90797 9.08191 9.86375 9.01573 9.80801 8.95998L8.18201 7.33398L9.80801 5.70798C9.86375 5.65224 9.90797 5.58606 9.93814 5.51322C9.96831 5.44039 9.98384 5.36232 9.98384 5.28348C9.98384 5.20465 9.96831 5.12658 9.93814 5.05375C9.90797 4.98091 9.86375 4.91473 9.80801 4.85898C9.75226 4.80324 9.68608 4.75902 9.61325 4.72885C9.54041 4.69868 9.46235 4.68315 9.38351 4.68315C9.30467 4.68315 9.22661 4.69868 9.15377 4.72885C9.08093 4.75902 9.01475 4.80324 8.95901 4.85898L7.33301 6.48498Z"
                                                fill="#fff" />
                                        </svg> </span> </mat-icon>
                            </mat-chip>
                        </div>




                        <!-- <div class="mb-4 pb-1">
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Date of birth">
                        </div> -->
                        <div class="mb-4 pb-1">
                            <textarea class="cmn-textarea form-control fs18" rows="4" placeholder="Bio"
                                formControlName="bio" (keydown)="checkEvent($event)"></textarea>
                        </div>


                        <div class="d-flex justify-content-between align-items-center  mb-5  custom-toggle">
                            <h5 class="mb-0">Available for custom</h5>
                            <mat-slide-toggle class="fs12" [(ngModel)]="availCustom" (change)="customChange($event)"
                                [ngModelOptions]="{standalone: true}" formControlName="availCustom"></mat-slide-toggle>
                        </div>

                        <button class="btn w-100 purple_btn btn70 fw-bold" 
                         (click)="addModelData()">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </form>

</section>

<app-studio-footer> </app-studio-footer>