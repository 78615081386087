import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { MessagehandlingService } from '../message/messagehandling.service';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // studio panel
  baseUrl = environment.baseUrl;
  licenceAndLogoUrl = 'https://php.parastechnologies.in/chokeChamber/storage/app/public/uploads/studio/'
  // baseurl = environment.baseurl;
  modelImageUrl = 'https://php.parastechnologies.in/chokeChamber/storage/app/public/uploads/models/'
  clipsImageUrl = 'https://php.parastechnologies.in/chokeChamber/storage/app/public/uploads/clips/'
  notificationUrl = 'https://php.parastechnologies.in/chokeChamber/storage/app/public/uploads/'
  constructor(private http: HttpClient, public router: Router, private message: MessagehandlingService) { }

  login(body: any) {
    return this.http.post(this.baseUrl + `/login`, body)
  }

  register(body: any) {
    return this.http.post(this.baseUrl + `/register`, body)
  }

  getProfile() {
    return this.http.get(this.baseUrl + `/profile`)
  }

  updateProfile(body: any) {
    return this.http.post(this.baseUrl + `/profile/update`, body)
  }

  updateAddress(body: any) {
    return this.http.post(this.baseUrl + `/profile/address/update`, body)
  }

  forgotPassword(body: any) {
    return this.http.post(this.baseUrl + `/forgot/password`, body)
  }

  verifyOtp(body: any) {
    return this.http.post(this.baseUrl + `/email/verify`, body)
  }

  resetPassword(body: any) {
    return this.http.post(this.baseUrl + `/reset/password`, body)
  }

  resendOtp(body: any) {
    return this.http.post(this.baseUrl + `/email/resend`, body)

  }

  addModel(body: any) {
    return this.http.post(this.baseUrl + `/model/add`, body)
  }

  modelData(body: any) {
    return this.http.post(this.baseUrl + `/model/list`, body)
  }

  deleteModel(id: any) {
    return this.http.delete(this.baseUrl + `/model/delete/${id}`)
  }

  getModelDetail(id: any) {
    return this.http.get(this.baseUrl + `/model/detail/${id}`)
  }

  updateModelDetail(body: any) {
    return this.http.post(this.baseUrl + `/model/update`, body)
  }

  deleteTag(id: any) {
    return this.http.delete(this.baseUrl + `/model/tag/delete/${id}`)
  }


  clipData(body: any) {
    return this.http.post(this.baseUrl + `/clip/list`, body)
  }

  addClip(body: any) {
    return this.http.post(this.baseUrl + `/clip/add`, body)
  }

  modelCategories() {
    return this.http.get(this.baseUrl + `/categories`)
  }
  upload(body: any) {
    return this.http.post(this.baseUrl + `/clip/upload`, body)
  }

  deleteClip(id: any) {
    return this.http.delete(this.baseUrl + `/clip/delete/${id}`)

  }

  clipDetail(id: any) {
    return this.http.get(this.baseUrl + `/clip/detail/${id}`)

  }

  updateClip(body: any) {
    return this.http.post(this.baseUrl + `/clip/update`, body)
  }

  revenue(body: any) {
    return this.http.post(this.baseUrl + `/revenue`, body)

  }



  // user panel 

  userBaseurl = 'https://php.parastechnologies.in/chokeChamber/api/v1/user'
  userCatFilePath = 'https://php.parastechnologies.in/chokeChamber/storage/app/public/uploads/categories/'
  userFilePath = 'https://php.parastechnologies.in/chokeChamber/storage/app/public/uploads/users/'

  loginUser(body: any) {
    return this.http.post(this.userBaseurl + `/login`, body)
  }

  registerUser(body: any) {
    return this.http.post(this.userBaseurl + `/register`, body)
  }

  logout(body: any) {
    return this.http.post(this.userBaseurl + `/logout`, body)

  }

  forgotPasswordUser(body: any) {
    return this.http.post(this.userBaseurl + `/forgot/password`, body)
  }

  resetPasswordUser(body: any) {
    return this.http.post(this.userBaseurl + `/reset/password`, body)
  }

  verifyOtpUser(body: any) {
    return this.http.post(this.userBaseurl + `/email/verify`, body)
  }

  resendOtpUser(body: any) {
    return this.http.post(this.userBaseurl + `/email/resend`, body)

  }

  changePassword(body: any) {
    return this.http.post(this.userBaseurl + `/password/change`, body)
  }

  profileUser() {
    return this.http.get(this.userBaseurl + `/profile`)
  }

  updateProfileUser(body: any) {
    return this.http.post(this.userBaseurl + `/profile/update`, body)
  }


  updateEmail(body: any) {
    return this.http.post(this.userBaseurl + `/email/change`, body)

  }

  categories() {
    return this.http.get(this.userBaseurl + `/categories`)
  }

  studios() {
    return this.http.get(this.userBaseurl + `/studios`)

  }

  models(body: any) {
    return this.http.post(this.userBaseurl + `/models`, body)

  }

  modelDetailUser(id: any) {
    return this.http.get(this.userBaseurl + `/model/detail/${id}`)
  }

  studioDetailUser(id: any) {
    return this.http.get(this.userBaseurl + `/studio/detail/${id}`)
  }

  clipDetailUser(id: any) {
    return this.http.get(this.userBaseurl + `/clip/detail/${id}`)

  }

  clipsBasedOnCategory(body: any) {
    return this.http.post(this.userBaseurl + `/category/clips`, body)
  }

  clips(body: any) {
    return this.http.post(this.userBaseurl + `/clips`, body)
  }

  addFavourite(body: any) {
    return this.http.post(this.userBaseurl + `/favourite/add`, body)

  }

  page(pageType: any) {
    return this.http.get(this.userBaseurl + `/page/${pageType}`)

  }

  home(body:any) {
    return this.http.post(this.userBaseurl + `/home`,body)
  }

  favouriteList(body: any) {
    return this.http.post(this.userBaseurl + `/favourite/list`, body)
  }

  categorySearch(body: any) {
    return this.http.post(this.userBaseurl + `/category/search`, body)

  }

  filter(body: any) {
    return this.http.post(this.userBaseurl + `/filter`, body)

  }

  addToCart(body: any) {
    return this.http.post(this.userBaseurl + `/cart/add`, body)

  }

  cartList() {
    return this.http.get(this.userBaseurl + `/cart/list`)

  }

  deleteItem(id: any) {
    return this.http.delete(this.userBaseurl + `/cart/item/delete/${id}`)

  }

  contactus(body: any) {
    return this.http.post(this.userBaseurl + `/contactus`, body)
  }

  addSubscription(body: any) {
    return this.http.post(this.userBaseurl + `/subscription/add`, body)

  }

  getSubscriptionDetail() {
    return this.http.get(this.userBaseurl + `/subscription/detail`)

  }

  cancelSubscription(body: any) {
    return this.http.post(this.userBaseurl + `/subscription/cancel`, body)

  }

  checkout(body: any) {
    return this.http.post(this.userBaseurl + `/checkout`, body)

  }

  buynow(body: any) {
    return this.http.post(this.userBaseurl + `/buynow`, body)

  }

  addToken(body: any) {
    return this.http.post(this.userBaseurl + `/token/add`, body)

  }

  tokenDetail() {
    return this.http.get(this.userBaseurl + `/token/detail`)

  }

  notificationList(body: any) {
    return this.http.post(this.userBaseurl + `/notifications`, body)

  }

  purchaseHistory(body: any) {
    return this.http.post(this.userBaseurl + `/purchased/history`, body)
  }

  purchaseToken(body: any) {
    return this.http.post(this.userBaseurl + `/token/purchased/history`, body)
  }

  clipsBasedOnStudio(body: any) {
    return this.http.post(this.userBaseurl + `/studio/clips`, body)
  }

  modelsBasedOnStudio(body: any) {
    return this.http.post(this.userBaseurl + `/studio/models`, body)

  }

  addCard(body: any) {
    return this.http.post(this.userBaseurl + `/card/add`, body)

  }

  cardList() {
    return this.http.get(this.userBaseurl + `/card/list`)

  }

  deleteCard(id: any) {

    return this.http.delete(this.userBaseurl + `/card/delete/${id}`)

  }


  notificationsDelete() {
    return this.http.delete(this.userBaseurl + `/notifications/delete`)

  }

}
