<section class="login-sec d-flex align-items-center justify-content-center p-4">
  <div class="content-box">
    <div class="top-sec d-block d-lg-flex align-items-center justify-content-between mb-4">
      <h2 class="ff_kalam font-normal text-white mb-0">Login</h2>
      <div class="right-sec">
        <p class="opacity-50 mb-2 fw-normal">Don’t have an account?</p>
        <h5 role="button" class="fs18 fw-medium mb-0 d-flex align-items-center" [routerLink]="['/studio-signup']">
          <span class="pe-2">Sign Up</span> <svg class="ms-1" width="20" height="14" viewBox="0 0 20 14" fill="none">
            <path d="M1 7L18.1429 7" stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M13.8571 13L19 7L13.8571 1" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </h5>
      </div>
    </div>

    <form [formGroup]="loginForm">
      <div class="mb-4 pb-1">
        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email address</label>
        <input type="email" formControlName="email" class="form-control fs18 cmn-input input_60 ff_Roboto"(keydown)="checkEvent($event)"
          placeholder="Enter email address">
          <small *ngIf="!loginForm.controls?.['email']?.valid &&
          loginForm.controls?.['email']?.dirty">
              <small
                  *ngIf="loginForm.controls?.['email']?.hasError('invalidEmail') && loginForm.controls?.['email']?.value"
                  class="error_message">Invalid email !
              </small>
              <small class="error_message"
                  *ngIf="loginForm.controls?.['email']?.hasError('required')">This field is
                  Mandatory !
              </small>
          </small>
      </div>

      <div class="mb-5">
        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Password</label>
        <input type="PASSWORD" formControlName="password" class="form-control fs18 cmn-input input_60 ff_Roboto"
          placeholder="Enter password"  (keydown)="checkEvent($event)">
          <small *ngIf="!loginForm.controls?.['password']?.valid &&
          loginForm.controls?.['password']?.dirty" class="error_main_div">
              <small *ngIf="loginForm.controls?.['password']?.hasError('required')"
                  class="error_message">This field
                  is
                  Mandatory !
              </small>
          </small>

      </div>
      <div class="forgot-pwd text-end pt-1 mb-5 lh1">
        <span role="button" class="fs18 fw-normal opacity-50 text-decoration-underline" [routerLink]="['/studio-forgot-password']">Forgot Password?</span>
     </div>
      <!-- [routerLink]="['/studio-home']" -->
      <button class="btn w-100 purple_btn btn70 fw-bold" type="submit" (click)="login()"  (keyup.enter)="login()">Login</button>
    </form>
  </div>
</section>