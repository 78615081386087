<!-- <app-header></app-header> -->
<section class="edit-cmn-sec verify-email pt-4 sec_padding_bottom">
    <div class="container">
        <div class="content-sec mx-auto text-center">
            <div class="img-wrap mb-5">
                <img src="assets/images/verify-email-img.svg">
            </div>
            <h4 class="fw-medium mb-3">Check Your Email</h4>
            <p class="fw-light color_grey mb-4 pb-3">Please enter the code we sent to <span class="color_purple fw-medium"> {{email}} </span> to help us to protect your account.
                You should expect to receive the code within the next five minutes. Be sure to check your spam or junk folder just in case it.</p>
            <form [formGroup]="verifyForm">
                <div class="otp-outer mb-5">
                    <ngx-otp-input [config]="otpInputConfig" formContolName="otp"(otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)"></ngx-otp-input>
                </div>
                <p class="text-center mb-3 pb-1 lh1 pt-3"><span class="color_red resend-code fw-bold" role="button " (click)="resendCode()">Resend Code?</span></p>
                <button type="submit" class="btn w-100 purple_btn btn70 fw-bold" (click)="submit()" [disabled]="verifyForm.invalid" (keyup.enter)="submit()"> Submit code</button>
              
            </form>
        </div>
    </div>
</section>

<!-- <app-footer></app-footer> -->

