import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IVideoConfig } from "ngx-video-list-player";
import { ApiService } from 'src/app/common/api-service/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clip-details',
  templateUrl: './clip-details.component.html',
  styleUrls: ['./clip-details.component.scss']
})
export class ClipDetailsComponent implements OnInit {

  config: IVideoConfig = {
    sources: [
      {
        src: 'assets/videos/demovedio.mp4',
      },
      {
        src: 'assets/videos/demovedio1.mp4',
      }]
  };
  id: any;
  modelImage: any
  clipdata: any = []
  baseUrl= 'https://php.parastechnologies.in/chokeChamber/'
  imageUrl ='storage/app/public/uploads/clips/'
  tags: any=[]
  videoData: any;

  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        this.getDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
  }


  getDetail(id: any) {
    this.api.clipDetail(id).subscribe((res: any) => {
      this.clipdata = res.data
      this.tags=res.data.tags
      // imageUrl + clipdata.image
      setTimeout(() => {
        this.modelImage = this.api.licenceAndLogoUrl + res.data.logo
        this.videoData=this.baseUrl +this.imageUrl+ res.data.trailer
      }, 1000);

    })
  }

}
