import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { DeleteClipComponent } from '../model/delete-clip/delete-clip.component';
declare const $: any;

@Component({
  selector: 'app-clips',
  templateUrl: './clips.component.html',
  styleUrls: ['./clips.component.scss']
})
export class ClipsComponent implements OnInit {
  clipdata: any = []
  baseUrl = 'https://php.parastechnologies.in/chokeChamber/'
  imageUrl = 'storage/app/public/uploads/clips/'
  constructor(public dialog: MatDialog, private api: ApiService, private message: MessagehandlingService, private router: Router) { }

  ngOnInit(): void {
    this.clipData()
   
   setTimeout(() => {
    $(document).ready( () => {
      $('#clipsTable').DataTable({

        // searching: false,
        // data: this.clipdata,

        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, 'All'],
        ],
      });
    });
  //  / alert(1)
   }, 3000);

  

  }



  clipData() {
    let fd = new FormData
    fd.append('pageNo', '0')
    this.api.clipData(fd).subscribe((res: any) => {
      if (res) {
        // this.message.sucessMessage(res.message, 2000)
        this.clipdata = res.data
      }

    })
  }

  viewDetail(id: any) {
    this.router.navigate([`/studio-clip-details/${id}`])
  }

  editClip(id: any) {
    sessionStorage.setItem('id', id)
    this.router.navigate([`/studio-edit-clips/${id}`])
  }

  deleteClip(id: any) {
    this.dialog.open(DeleteClipComponent, {
      panelClass: ['', 'delete_model'],
      disableClose: true,
      width: '500px',
      data: id
      // height: '100%',
    }).afterClosed()
      .subscribe((res) => {
        setTimeout(() => {
          this.clipData()
        }, 2000);
      });
  }

}
