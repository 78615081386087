import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-edit-clip',
  templateUrl: './edit-clip.component.html',
  styleUrls: ['./edit-clip.component.scss']
})
export class EditClipComponent implements OnInit {
  // config: IVideoConfig = {
  //   sources: [
  //     {
  //       src: 'assets/videos/demovedio.mp4',
  //     }]
  // };
  value: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  clipChanged=false
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  EditClipForm: FormGroup
  clip: any;
  trailer: any;
  image: any;
  currentRoute: any;

  id: any;
  clipdata: any = []
  clipData: any;
  clipFinalData: any;
  trailerData: any;
  trailerFinalData: any;
  imageData: any;
  imageFinalData: any;
  modelvalue: any;
  modelID: any;
  modelArray: any = []
  clipDetail: any;
  categories: any = []
  categoryvalue: any;
  categoryID: any;
  cliptagData: any = []
  baseUrl = 'https://php.parastechnologies.in/chokeChamber/'
  baseClipUrl='https://chokechambernew.s3.amazonaws.com/'
  imageUrl = 'storage/app/public/uploads/clips/'
  apiUrl = 'https://php.parastechnologies.in/chokeChamber/api/v1/studio';
  mid: any;
  selectedModelName: any = []
  selectedcategory: any = []
  cid: any;
  videoClipDuration: any
  uploadProgress: number=0
  cumulativeUploadProgress: number=0
  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService, private message: MessagehandlingService,private http: HttpClient, private fb: FormBuilder) {

    this.EditClipForm = this.fb.group({
      uploadVideo: new FormControl(''),
      uploadTrailer: new FormControl(''),
      uploadCoverImage: new FormControl(''),
      title: new FormControl(''),
      price: new FormControl(''),
      tags: new FormControl(''),
      duration:new FormControl('',[Validators.required, this.durationFormatValidator()]),
      categoryvalue: [[]],
      modelvalue: [[]],
      description: new FormControl(''),

    })
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        this.getDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }


  ngOnInit(): void {
    this.categoryData()
    this.modelData()
  }

  durationFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validFormat = /^[0-9]+(\s*(minute|min|minutes|second|sec|seconds))(\s*[0-9]+\s*(second|sec|seconds))?$/i.test(control.value);
      return validFormat ? null : { invalidFormat: { value: control.value } };
    };
  }


  categoryData() {
    this.api.modelCategories().subscribe((res: any) => {
      this.categories = res.data
    })
  }

  categoryClass(event: any) {

    this.categoryvalue = event;
    this.categoryID = event.id;

  }

  getDetail(id: any) {
    this.api.clipDetail(id).subscribe((res: any) => {
      this.clipdata = res.data
      let tags = res.data.tags
      setTimeout(() => {
        this.EditClipForm.patchValue({
          title: res.data.title,
          description: res.data.description,
          price: res.data.price,
          duration:res.data.duration==undefined ? '' :res.data.duration,
          categoryvalue: res.data.categories.map((category: any) => category?.cateID),
          modelvalue: res.data.models.map((model: any) => model?.MID),

        })

        // setTimeout(() => {
        this.modelArray.forEach((element: any) => {
          element.checked = this.EditClipForm.value.modelvalue.includes(element.id);
        });

        this.categories.forEach((element: any) => {
          element.checked = this.EditClipForm.value.categoryvalue.includes(element.id);
        });
        // }, 1000);
      }, 3000);

      this.imageFinalData = res.data.image
      this.clipFinalData = res.data.video
      this.trailerFinalData = res.data.trailer
      tags.forEach((element: any, index: any) => {
        this.cliptagData.push(element)


      });

      if (res.data.image == '' || res.data.image == null || res.data.image == undefined) {
        this.image = '';
      }
      else {
        setTimeout(() => {
          this.image = this.baseUrl + this.imageUrl + res.data.image
        }, 2000);
      }
      if (res.data.video == '' || res.data.video == null || res.data.video == undefined) {
        this.clip = '';
      }
      else {
        setTimeout(() => {
          this.clip = this.baseClipUrl + res.data.video
          console.log(this.clip);
          
        }, 2000);
      }
      if (res.data.trailer == '' || res.data.trailer == null || res.data.trailer == undefined) {
        this.trailer = '';
      }
      else {
        setTimeout(() => {
          this.trailer = this.baseUrl + this.imageUrl + res.data.trailer
        }, 2000);
      }

    })

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  modelData() {
    let fd = new FormData
    fd.append('pageno', '0')
    this.api.modelData(fd).subscribe((res: any) => {
      this.modelArray = res.data
    })
  }

  modelClass(event: any) {
    this.modelvalue = event;
    this.modelID = event?.id
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    this.value = event.value;
    if ((this.value || '').trim()) {
      this.cliptagData.push({ id: '0', name: this.value.trim() });
      // this.clipDetail.push(this.value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(model: any): void {
    const index = this.cliptagData.indexOf(model);
    if (index >= 0) {
      this.cliptagData.splice(index, 1);
    }
    this.api.deleteTag(model.id).subscribe((ele: any) => {
      this.message.sucessMessage(ele.message, 2000)
    })
  }

  onFileChange(event: any, type: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      const video = document.createElement('video');
      video.src = window.URL.createObjectURL(file);
      video.preload = 'metadata';
      reader.onload = e => {
        switch (type) {
          case 'clip':
            this.clipChanged=true
            this.clip = reader.result
            this.uploadClipVideo(file)
            // this.clipData = file
            // video.onloadedmetadata = () => {
            //   this.videoClipDuration = video.duration;
            //   // alert(this.videoClipDuration)
            // }
            // let fd = new FormData
            // fd.append('file', this.clipData)
            // this.api.upload(fd).subscribe((res: any) => {
            //   this.clipFinalData = res.data
            // })
            break;
          case 'trailer':
            this.trailer = reader.result
            this.trailerData = file
            let fd1 = new FormData
            fd1.append('file', this.trailerData)
            this.api.upload(fd1).subscribe((res: any) => {
              this.trailerFinalData = res.data
            })
            break;
          case 'image':
            this.image = reader.result
            this.imageData = file
            let fd2 = new FormData
            fd2.append('file', this.imageData)
            this.api.upload(fd2).subscribe((res: any) => {
              this.imageFinalData = res.data
            })
            break;
        }

      };
      reader.readAsDataURL(file);

    }

  }

  // uploadClipVideo(file: File): void {
  //   this.initiateUpload(file.name).subscribe((initiateResponse: any) => {
  //     const uploadId = initiateResponse.uploadId;
  //     const fileName = initiateResponse.fileName
  //     const chunkSize = 2 * 1024 * 1024; // 1 MB chunk size
  //     const fileSize = file.size;
  //     let offset = 0;
  //     let chunkIndex = 0;

  //     const readNextChunk = () => {
  //       const chunkEnd = Math.min(offset + chunkSize, fileSize);
  //       const chunk = file.slice(offset, chunkEnd);
  //       offset += chunkSize;

  //       if (chunk.size > 0) {
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //           // const chunkData = (e.target as FileReader).result as any;
  //           const chunkData = file
  //           // Upload the chunk
  //           this.uploadChunk(uploadId, fileName, chunkIndex, chunkData).subscribe(() => {
  //             chunkIndex++;
  //             if (offset < fileSize) {
  //               readNextChunk();
  //             } else {

  //               this.completeUpload(fileName, uploadId).subscribe((completeResponse: any) => {
  //                 this.clipFinalData = completeResponse.data
  //                 this.message.sucessMessage(completeResponse.message, 1000)
  //               });
  //             }
  //           });
  //         };
  //         reader.readAsDataURL(chunk);
  //       }
  //     };

  //     // Start the chunking process
  //     readNextChunk();
  //   });
  // }
  uploadClipVideo(file: File): void {
    this.initiateUpload(file.name).subscribe((initiateResponse: any) => {
      const uploadId = initiateResponse.uploadId;
      const fileName = initiateResponse.fileName;
      const chunkSize = 10 * 1024 * 1024;
      const fileSize = file.size;
      let offset = 0;
      let chunkIndex = 0;
  
      const readNextChunk = () => {
        const chunkEnd = Math.min(offset + chunkSize, fileSize);
        const chunk = file.slice(offset, chunkEnd);
        offset += chunkSize;
  
        if (chunk.size > 0) {
          this.uploadChunk(uploadId, fileName, chunkIndex, chunk).subscribe((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              if (offset < fileSize) {
                const chunkPercent = Math.round((100 * event.loaded) / event.total);
                this.cumulativeUploadProgress = Math.min(100, Math.round((chunkIndex * 100 + chunkPercent) / (fileSize / chunkSize)));
                this.uploadProgress = this.cumulativeUploadProgress;
              }
            } else if (event.type === HttpEventType.Response) {
              chunkIndex++;
              if (offset < fileSize) {
                readNextChunk();
              } else {
                this.completeUpload(fileName, uploadId).subscribe((completeResponse: any) => {
                  this.clipFinalData = completeResponse.data;
                  this.message.sucessMessage(completeResponse.message, 1000);
                  this.cumulativeUploadProgress = 100;
                  this.uploadProgress = this.cumulativeUploadProgress;
                });
              }
            }
          });
        }
      };
  
      readNextChunk();
    });
  }


  initiateUpload(fileName: string) {
    const url = `${this.apiUrl}/initiateUpload`;
    return this.http.post(url, { fileName });
  }

  uploadChunk(uploadId: string, fileName: string, index: number, chunk: Blob) {
    const url = `${this.apiUrl}/uploadChunk`;
    const formData = new FormData();
    formData.append('uploadId', uploadId);
    formData.append('fileName', fileName);
    formData.append('index', index.toString());
    formData.append('file', chunk);
    // return this.http.post(url, formData);
    return this.http.post(url, formData, {
      reportProgress: true, 
      observe: 'events'
    });
  }

  completeUpload(fileName: string, uploadId: string) {
    const url = `${this.apiUrl}/completeUpload`;
    return this.http.post(url, { fileName, uploadId });

  }



  removeImage(type: any) {
    switch (type) {
      case 'clip':
        this.clip = ''
        break;
      case 'trailer':
        this.trailer = ''
        break;
      case 'image':
        this.image = ''
        break;
    }
  }

  updateClip() {
    let fd = new FormData
    fd.append('id', this.id)
    fd.append('MID', this.EditClipForm.value.modelvalue)
    fd.append('CID', this.EditClipForm.value.categoryvalue)
    fd.append('title', this.EditClipForm.value.title)
    fd.append('description', this.EditClipForm.value.description)
    fd.append('price', this.EditClipForm.value.price)
    fd.append('image', this.imageFinalData)
    fd.append('trailer', this.trailerFinalData)
    fd.append('video', this.clipFinalData)
    fd.append('duration', this.EditClipForm.value.duration)

    fd.append('tags', JSON.stringify(this.cliptagData))

    this.api.updateClip(fd).subscribe((res: any) => {

      this.message.sucessMessage(res.message, 2000)
      this.router.navigate(['/studio-clips'])

    })
  }
}
